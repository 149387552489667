<template>
  <v-container fluid>
    <resumen></resumen>
  </v-container>
</template>

<script>
import Resumen from "../components/Resumen";

export default {
  components: {
    Resumen
  }
};
</script>
